import {
  FoodGPT,
  FSABatchProcessing,
  FSASingleProduct,
  GenerateAttributesBatchProcess,
  GenerateAttributesSingleProduct,
  GordanAIIngredientsGeneration,
  GordanAISalesChat,
  HomePage,
  LandingPageProductRecommendation,
  PageNotFound,
  SkuMatchingBatchProcess,
  SkuMatchingSingleProduct,
  TasksPane,
  DeAbbreviationSingleProduct,
  DeAbbreviationBatchProcessing
} from "../pages";
import {
  Home,
  Database,
  ShoppingBag,
  Activity,
  Cpu,
  Aperture,
  Codesandbox,
  Type
} from "@geist-ui/icons";

export interface pageContent {
  key: number;
  title: string;
  path: string;
  element?: any;
  showOnMenu: boolean;
  icon?: any;
  longPath?: string;
  parentId?: number;
  tileTitle?: string;
  description?: string;
}
export interface pageInterface extends pageContent {
  nestedRoutes?: pageContent[];
}

export const pages: pageInterface[] = [
  {
    key: 1,
    title: "Home",
    path: "/",
    element: HomePage,
    showOnMenu: true,
    icon: Home,
  },
  {
    key: 2,
    title: "FSA",
    path: "fsa",
    showOnMenu: true,
    icon: Database,
    nestedRoutes: [
      {
        key: 21,
        title: "Batch Classification",
        path: "batch-process",
        element: FSABatchProcessing,
        showOnMenu: true,
        icon: Database,
        description:
          "Obtain L1-L4 category information for products contained in a provided CSV file.",
      },
      {
        key: 22,
        title: "Single Product Classification",
        path: "single-product",
        element: FSASingleProduct,
        showOnMenu: true,
        icon: Database,
        description: "Obtain L1-L4 category information for a single product.",
      },
    ],
  },
  {
    key: 3,
    title: "Product Recommendation",
    path: "product-recommendation",
    showOnMenu: false,
    icon: ShoppingBag,
    nestedRoutes: [
      {
        key: 31,
        title: "Landing Page",
        path: "landing-page",
        element: LandingPageProductRecommendation,
        showOnMenu: false,
        icon: ShoppingBag,
        description: "Explore recommended products to be used in Landing Page.",
      },
    ],
  },
  {
    key: 4,
    title: "Generate Attributes",
    path: "generate-attributes",
    showOnMenu: true,
    icon: Cpu,
    nestedRoutes: [
      {
        key: 41,
        title: "Batch Process",
        path: "batch-process",
        element: GenerateAttributesBatchProcess,
        showOnMenu: true,
        icon: Cpu,
        description:
          "Obtain product attributes for products contained in a provided CSV file.",
      },
      {
        key: 42,
        title: "Single Product",
        path: "single-product",
        element: GenerateAttributesSingleProduct,
        showOnMenu: true,
        icon: Cpu,
        description: "Obtain product attributes for a single product.",
      },
    ],
  },
  {
    key: 5,
    title: "SKU Matching",
    path: "sku-matching",
    showOnMenu: true,
    icon: Aperture,
    nestedRoutes: [
      {
        key: 51,
        title: "Batch Process",
        path: "batch-process",
        element: SkuMatchingBatchProcess,
        showOnMenu: true,
        icon: Aperture,
        description:
          "Obtain manufacturer product name for distibutor products contained in a provided CSV file.",
      },
      {
        key: 52,
        title: "Single Product",
        path: "single-product",
        element: SkuMatchingSingleProduct,
        showOnMenu: true,
        icon: Aperture,
        description:
          "Obtain manufacturer product name for a single distributor product.",
      },
      {
        key: 53,
        title: "FoodGPT",
        path: "food-gpt",
        element: FoodGPT,
        showOnMenu: true,
        icon: Aperture,
        description: "AI System for defining SKU Matcher using chatGPT.",
      },
    ],
  },
  {
    key: 6,
    title: "Gordon AI",
    path: "gordon-ai",
    showOnMenu: true,
    icon: Codesandbox,
    nestedRoutes: [
      {
        key: 61,
        title: "Chat with your sales data",
        path: "sales-chat",
        element: GordanAISalesChat,
        showOnMenu: true,
        icon: Codesandbox,
        description:
          "Ask series of questions on a selected distributor's and their operators performance for the last 3 months.",
      },
      {
        key: 62,
        title: "Generate Ingredients",
        path: "generate-ingredients",
        element: GordanAIIngredientsGeneration,
        showOnMenu: true,
        icon: Codesandbox,
        description:
          "Generate ingredients for a given menu item from a restaurant of your choice.",
      },
    ],
  },
  {
    key: 7,
    title: "De-Abbreviation",
    path: "de-abbreviation",
    showOnMenu: true,
    icon: Type,
    nestedRoutes: [
      {
        key: 71,
        title: "Single Product",
        path: "single-product",
        element: DeAbbreviationSingleProduct,
        showOnMenu: true,
        icon: Type,
        description:
          "De-Abbreviate Product name and return expanded product name with mappings.",
      },
      {
        key: 72,
        title: "Batch Processing",
        path: "batch-processing",
        element: DeAbbreviationBatchProcessing,
        showOnMenu: true,
        icon: Type,
        description:
          "Expand de-abbreviations in products names in CSV.",
      },
    ],
  },
  {
    key: 8,
    title: "Tasks Pane",
    path: "tasks-pane",
    element: TasksPane,
    showOnMenu: true,
    icon: Activity,
    description: "Monitor the progress of the tasks created.",
  },
  {
    key: 999,
    title: "Page Not Found",
    path: "*",
    element: PageNotFound,
    showOnMenu: false,
  },
];
