import { Breadcrumbs, Button, Fieldset, Input, Loading } from "@geist-ui/core";
import { isEmpty } from "lodash";
import { useState } from "react";
import { postRequest } from "../../helpers/makeAPIRequests";
import { GET_FSA_SINGLE_PRODUCT_API } from "../../lib/endpoints";
import "./styles.css";

const FSALevels = [1, 2, 3, 4];

const FSASingleProduct = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [input, setInput] = useState<string | null>(null);
  const [fsaLevels, setFsaLevels] = useState<string[]>([])

  const handleInputChange = (e: any) => {
    setInput(e?.target?.value);
  };

  const handleClearIconClick = () => {
    setInput(null)
    setFsaLevels([])
  }

  const handleButtonClick = async () => {
    if (!input) {
        return null
    }
    const categoryValues = []
    setLoading(true);
    const res = await postRequest(GET_FSA_SINGLE_PRODUCT_API, {
      product_names: [input],
    });
    const FSAObject = res?.data?.data[0]?.[`${input}`]
    for (const property in FSAObject) {
        categoryValues.push(FSAObject[property])
    }
    setFsaLevels(categoryValues)
    setLoading(false);
  };

  return (
    <div>
      <Breadcrumbs style={{ paddingBottom: "50px" }}>
        <Breadcrumbs.Item>Home</Breadcrumbs.Item>
        <Breadcrumbs.Item>FSA</Breadcrumbs.Item>
        <Breadcrumbs.Item>Single Product</Breadcrumbs.Item>
      </Breadcrumbs>

      <div className="fsa-single-input">
        <Input
          placeholder="Enter Product name"
          clearable
          width={"100%"}
          onChange={handleInputChange}
          disabled={loading}
          onClearClick={handleClearIconClick}
        >
          Product Name
        </Input>
        <div className="fsa-single-input-button" onClick={handleButtonClick}>
          <Button type="success" ghost disabled={loading || !input}>
            Get FSA Categories
          </Button>
        </div>
        {loading && <Loading />}
        {!isEmpty(fsaLevels) && <div className="fsa-single-input-fieldset">
          <Fieldset width={"200%"}>
            <Fieldset.Title>FSA Categories</Fieldset.Title>
            <Fieldset.Subtitle>
              {FSALevels?.map((level: number) => (
                <div>
                  L{level} Category: {fsaLevels[level-1]}
                </div>
              ))}
            </Fieldset.Subtitle>
          </Fieldset>
        </div>}
      </div>
    </div>
  );
};

export default FSASingleProduct;
