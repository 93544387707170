import {
  Breadcrumbs,
  Button,
  Fieldset,
  Input,
  Loading,
  Table,
  Text,
  Toggle,
} from "@geist-ui/core";
import { isEmpty } from "lodash";
import { useState } from "react";
import { postRequest } from "../../helpers/makeAPIRequests";
import { GET_SKU_MATCHING_SINGLE_PRODUCT_API } from "../../lib/endpoints";
import "./styles.css";

const SkuMatchingSingleProduct = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [input, setInput] = useState<string | null>(null);
  const [sku, setSku] = useState<any[]>([]);

  const [customSourceFile, setCustomSourceFile] = useState<boolean>(false);
  const [customSourceFileName, setCustomSourceFileName] = useState<
    string | null
  >(null);
  const [useCustomSourceFile, setUseCustomSourceFile] =
    useState<boolean>(false);

  const handleInputChange = (e: any) => {
    setInput(e?.target?.value);
  };

  const handleClearIconClick = () => {
    setInput(null);
    setSku([]);
  };

  const handleToggleOnChange = () => {
    setCustomSourceFile(!customSourceFile);
  };

  const handleCustomSourceFileName = (e: any) => {
    setCustomSourceFileName(e?.target?.value);
  };

  const handleCustomSourceFile = () => {
    if (customSourceFileName) {
      setUseCustomSourceFile(true);
    }
  };

  const handleButtonClick = async () => {
    if (!input) {
      return null;
    }
    setLoading(true);
    try {
      const res = await postRequest(GET_SKU_MATCHING_SINGLE_PRODUCT_API, {
        dp_product_name: input,
        source_csv: useCustomSourceFile ? customSourceFileName : null,
      });
      console.log("res", res?.data?.["products_with_scores"]);
      const tableData = res?.data?.["products_with_scores"]?.map(
        (item: any) => {
          return {
            name: item[0],
            score: item[1],
          };
        }
      );
      console.log("tableData", tableData)
      setSku(tableData);
    } catch (error) {
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <div>
      <Breadcrumbs style={{ paddingBottom: "50px" }}>
        <Breadcrumbs.Item>Home</Breadcrumbs.Item>
        <Breadcrumbs.Item>SKU Matching</Breadcrumbs.Item>
        <Breadcrumbs.Item>Single Product</Breadcrumbs.Item>
      </Breadcrumbs>

      <div style={{ display: "flex", alignItems: "center" }}>
        <Text b h5 className="sku-single-text-label">
          Use custom source file
        </Text>

        <Toggle
          style={{ marginTop: "5px" }}
          onChange={handleToggleOnChange}
          disabled={loading}
        />
      </div>

      {customSourceFile && (
        <>
          <Text style={{marginTop: 0}}>
            Note: Make sure the custom source file is uploaded in the S3 bucket.
          </Text>
          <div style={{ display: "flex", alignItems: "center", marginBottom: "30px" }}>
            <Input
              placeholder="Add custom source file name. e.g., source.csv"
              width="400px"
              onChange={handleCustomSourceFileName}
              clearable
              onClearClick={() => {
                setCustomSourceFileName(null);
              }}
            />
            <Button
              style={{ marginLeft: "10px" }}
              type="success"
              ghost
              onClick={handleCustomSourceFile}
              disabled={loading || !customSourceFile}
            >
              Submit
            </Button>
          </div>
        </>
      )}

      <div className="sku-single-input">
        <Input
          placeholder="Enter Product name"
          clearable
          width={"100%"}
          onChange={handleInputChange}
          disabled={loading}
          onClearClick={handleClearIconClick}
        >
          Product Name
        </Input>
        <div className="sku-single-input-button" onClick={handleButtonClick}>
          <Button type="success" ghost disabled={loading || !input}>
            Get Manufacturer Product Name
          </Button>
        </div>
        {loading && <Loading />}
        {!isEmpty(sku) && (
          <div className="sku-single-input-table">
            <Table data={sku}>
              <Table.Column prop="name" label="Predicted Manufacture Product Name" />
              <Table.Column prop="score" label="Accuracy Score" />
            </Table>
          </div>
        )}
      </div>
    </div>
  );
};

export default SkuMatchingSingleProduct;
