import axios, { AxiosRequestConfig } from "axios";

const DEBUG = process.env.REACT_APP_NAME === "ML-UI local";

axios.interceptors.request.use(
  (config) => {
    if (DEBUG) {
      console.info("Axios Config: ", config);
    }
    return config;
  },
  (error) => {
    if (DEBUG) {
      console.log("Axios Request Error Request: ", error);
    }
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (DEBUG) {
      console.log("Axios Response Error Response: ", error, error?.response);
    }
    if (error?.response?.data?.message) {
      return Promise.reject(error?.response?.data?.message);
    }
    if (error?.response?.statusText) {
      return Promise.reject(error.response.statusText);
    }
    return Promise.reject(error.message);
  }
);

export const getRequest = async (path: string, params: any = {}) => {
  const response = await axios.get(`${path}`, { params });
  return response;
};

export const postRequest = async (
  path: string,
  body: any,
  configs?: AxiosRequestConfig
) => {
  const response = await axios.post(`${path}`, body, configs);
  return response;
};
