import { Breadcrumbs, Button, Card, Description, Text, Input } from "@geist-ui/core";
import { FilePlus, Upload } from "@geist-ui/icons";
import { AxiosResponse } from "axios";
import { ChangeEvent, useRef, useState } from "react";
import { postRequest } from "../../helpers/makeAPIRequests";
import {
  GET_FILE_UPLOAD_TO_S3_API,
  GET_DE_ABBREVIATION_BATCH_PROCESSING_API,
} from "../../lib/endpoints";
import "./styles.css";


// Interface for feedback
interface feedbackInterface {
  title: string;
  content: string;
}

// Batch Processing Page Component
const DeAbbreviationBatchProcessing = () => {

  // State management for file uploading
  const hiddenFileInput = useRef<any>();
  const [loading, setLoading] = useState<boolean>(false)
  const [file, setFile] = useState<File | null>(null);
  const [fileName, setFileName] = useState<string | null>(null);
  const [showFeedback, setShowFeedback] = useState<boolean>(false);
  const [callbackURL, setCallbackURL] = useState<string | null>(null);

//   Feedback messages states
  const [fileUploadFeedback, setFileUploadFeedback] =
    useState<feedbackInterface | null>({
      title: "File Upload",
      content: "Pending",
    });
  const [processFeedback, setProcessFeedback] =
    useState<feedbackInterface | null>({
      title: "Batch Processing",
      content: "Pending",
    });

    // Handle click in the refernce
  const handleClick = (event: any) => {
    hiddenFileInput.current!.click();
  };


  // Handling File Uploads
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const fileUploaded = event?.target?.files![0];
    setFileName(fileUploaded?.name);
    setFile(fileUploaded);
  };

  // Handle Callback change
  const handleCallbackChange = (event: ChangeEvent<HTMLInputElement>) => {
    const callback_URL = event?.target?.value;
    setCallbackURL(callback_URL);
  }


  // CSV Processing function
  const processCSVFile = async (file: File) => {

    // Show feedbacks and loading
    setShowFeedback(true);
    setLoading(true)
    setFileUploadFeedback({ title: "File Upload", content: "Pending" });
    setProcessFeedback({ title: "Batch Processing", content: "Pending" });

    // Create form data object 
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_type", "de-abbreviation");

    // Handling the file uploading response
    let fileUploadRes: AxiosResponse<any, any>;

    // Handling feedback message 
    try {
      fileUploadRes = await postRequest(GET_FILE_UPLOAD_TO_S3_API, formData);
      setFileUploadFeedback({
        title: "File Upload",
        content: "Succesfully uploaded to S3",
      });
    } catch (error) {
      setFileUploadFeedback({
        title: "File Upload",
        content: "Error uploading to S3",
      });
      return null;
    }

    // Get the uploaded file details in the response
    try {
      const data = {
        original_file_name: fileUploadRes?.data?.data?.original_filename,
        uploaded_file_name: fileUploadRes?.data?.data?.uploaded_filename,
        callback_url: callbackURL,
        isSanitized: true
      };

      // Run the bacth processing and error handling
      await postRequest(GET_DE_ABBREVIATION_BATCH_PROCESSING_API, data);
      setProcessFeedback({ title: "Batch Processing", content: "Started. You will notify to the call back URL when the processing is finished" });
    } catch (error) {
      setProcessFeedback({
        title: "Batch Processing",
        content: `Error in starting batch processing: ${error}`,
      });
    }

    // Finished the processing with set loading to the false
    setLoading(false)
  };

  // Rendering the component
  return (
    <div>
      <Breadcrumbs style={{ paddingBottom: "50px" }}>
        <Breadcrumbs.Item>Home</Breadcrumbs.Item>
        <Breadcrumbs.Item>De-Abbreviation</Breadcrumbs.Item>
        <Breadcrumbs.Item>Batch Processing</Breadcrumbs.Item>
      </Breadcrumbs>

      <Text b h5 className="abbreviation-batch-text-label">
        Upload a file to process De-Abbreviation
      </Text>

      <Text>Note: Uploaded CSV file should have a column named: <b>ProdName</b></Text>

      <div style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
        <Button
          onClick={handleClick}
          icon={<FilePlus />}
          style={{ width: "25%" }}
        >
          Select a file
        </Button>
        <input
          type="file"
          ref={hiddenFileInput}
          onChange={handleChange}
          style={{ display: "none" }}
          accept=".csv, application/vnd.ms-excel, text/csv"
        />

        {fileName && (
          <Text h6 style={{ margin: "0 0 0 20px", fontWeight: "normal" }}>
            Selected File Name: {fileName}
          </Text>
        )}
      </div>

     {/* Input Field to get the Callback URL */}
     <div style={{ display: "flex", alignItems: "center", marginTop: "20px", marginBottom: "15px"}}>
        <Input className="abbreviation-callback-input" 
            placeholder="https://callback_url.com"
            onChange={handleCallbackChange} 
            width="50%">
                <Text h5>Callback URL *</Text>
            </Input>
     </div>


      <Button
        icon={<Upload />}
        style={{ width: "25%", marginTop: "20px" }}
        disabled={!fileName || !callbackURL}
        type="success"
        ghost
        onClick={() => processCSVFile(file!)}
      >
        Process CSV File
      </Button>
      {loading && <Text h6 className="abbreviation-batch-loading-text">The uploaded file is in process. Status of the request is shown below</Text>}
      {showFeedback && (
        <div style={{ marginTop: "20px" }}>
          <Card width={"50%"}>
            {fileUploadFeedback && (
              <Description
                title={fileUploadFeedback?.title}
                content={fileUploadFeedback?.content}
                style={{ marginBottom: "20px" }}
              />
            )}
            {processFeedback && (
              <Description
                title={processFeedback?.title}
                content={processFeedback?.content}
                style={{ marginBottom: "20px" }}
              />
            )}
          </Card>
        </div>
      )}
    </div>
  );
};

export default DeAbbreviationBatchProcessing;
