import {
  Button,
  Description,
  Grid,
  Input,
  Loading,
  Note,
  Text,
  Textarea,
  Toggle,
} from "@geist-ui/core";
import { useState } from "react";
import { postRequest } from "../../helpers/makeAPIRequests";
import { GET_SKU_MATCHING_FOOD_GPT_API } from "../../lib/endpoints";
import "./styles.css";

interface inputFieldsInterface {
  key: number;
  label: string;
  placeholder?: string;
  handleFunc: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const FoodGPT = () => {
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const [dpName, setDpName] = useState<string | null>(null);
  const [brand, setBrand] = useState<string | null>(null);
  const [packSize, setPackSize] = useState<string | null>(null);
  const [storageTemp, setStorageTemp] = useState<string | null>(null);
  const [gtin, setGtin] = useState<string | null>(null);
  const [manufacturer, setManufacturer] = useState<string | null>(null);

  const [textAreaValue, setTextAreaValue] = useState<string>("");
  const [disableTextArea, setDisableTextArea] = useState<boolean>(true);
  const [manufactureProd, setManufactureProd] = useState<string | null>(null);

  const handleDPNameInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    if (!input) {
      setErrorMsg("Distributor Product Name cannot be empty.");
      setTextAreaValue("")
    } else {
      setErrorMsg(null);
      setDpName(input);
      generateGptString(
        input,
        brand,
        packSize,
        storageTemp,
        gtin,
        manufacturer
      );
    }
  };

  const handleBrandInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    setBrand(input);
    generateGptString(dpName, input, packSize, storageTemp, gtin, manufacturer);
  };

  const handlePackSizeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    setPackSize(input);
    generateGptString(dpName, brand, input, storageTemp, gtin, manufacturer);
  };

  const handleStorageTempInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    setStorageTemp(input);
    generateGptString(dpName, brand, packSize, input, gtin, manufacturer);
  };

  const handleGtinInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    setGtin(input);
    generateGptString(
      dpName,
      brand,
      packSize,
      storageTemp,
      input,
      manufacturer
    );
  };

  const handleManufacturerInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    setManufacturer(input);
    generateGptString(dpName, brand, packSize, storageTemp, gtin, input);
  };

  const generateGptString = (
    dpName: any,
    brand: any,
    packSize: any,
    storageTemp: any,
    gtin: any,
    manufacturer: any
  ) => {
    let gptString = `Suggest the most matching manufacture product name for the distributor product, ${dpName}`;

    if (brand || packSize || storageTemp || gtin || manufacturer) {
    //   gptString = gptString + `, with the following attributes`;

      if (brand) {
        gptString = gptString + `, which is of Brand ${brand}`;
      }
      if (packSize) {
        gptString = gptString + `, with a Pack Size of ${packSize}`;
      }
      if (storageTemp) {
        gptString = gptString + `, having a Storage Temperature of ${storageTemp}`;
      }
      if (gtin) {
        gptString = gptString + `, with a product GTIN of ${gtin}`;
      }
      if (manufacturer) {
        gptString = gptString + `, whose Manufacturer is ${manufacturer}`;
      }
    }
    gptString = gptString + ". Return the name found on the trained dataset."
    setTextAreaValue(gptString);
  };

  const handleTextAreaInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const input = e.target.value;
    setTextAreaValue(input);
  };

  const getManufactureProduct = async () => {
    setErrorMsg(null)
    setLoading(true);
    let res = null;
    try {
      res = await postRequest(GET_SKU_MATCHING_FOOD_GPT_API, {
        query: textAreaValue,
      });
      setLoading(false);
      console.log("RESSSSSSS: ", res)
      setManufactureProd(res?.data?.data);
    } catch (error: any) {
      setLoading(false);
      setErrorMsg(error)
    }
  };

  const inputFields: inputFieldsInterface[] = [
    {
      key: 1,
      label: "Distributor Product Name *",
      handleFunc: handleDPNameInput,
    },
    { key: 2, label: "Brand", handleFunc: handleBrandInput },
    {
      key: 3,
      label: "Pack Size",
      placeholder: "e.g., 6/30 OZ",
      handleFunc: handlePackSizeInput,
    },
    {
      key: 4,
      label: "Storage Temperature",
      placeholder: "e.g., Frozen",
      handleFunc: handleStorageTempInput,
    },
    { key: 5, label: "GTIN", handleFunc: handleGtinInput },
    { key: 6, label: "Manufacturer", handleFunc: handleManufacturerInput },
  ];

  return (
    <div>
      <Text h4>Product Details</Text>
      <Grid.Container gap={2}>
        {inputFields?.map((inputField: inputFieldsInterface) => {
          return (
            <Grid xs={24} md={8} key={inputField.key}>
              <Input
                placeholder={inputField.placeholder}
                width="100%"
                onChange={inputField?.handleFunc}
              >
                {inputField?.label}
              </Input>
            </Grid>
          );
        })}
      </Grid.Container>

      {errorMsg && <Note type="error" style={{marginTop: "20px"}}>{errorMsg}</Note>}

      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Text h5 style={{ marginTop: "20px", marginRight: "20px" }}>
          Generated String. Edit:
        </Text>
        <Toggle
          style={{ marginTop: "5px" }}
          onChange={() => setDisableTextArea(!disableTextArea)}
          disabled={loading || !textAreaValue}
        />
      </div>
      <Textarea
        width="100%"
        disabled={disableTextArea}
        value={textAreaValue}
        onChange={handleTextAreaInput}
      />

      <Button
        onClick={getManufactureProduct}
        type="success"
        ghost
        style={{ marginTop: "20px" }}
        disabled={loading || !textAreaValue}
      >
        Get Manufacture Product
      </Button>

      {loading && <Loading />}

      {manufactureProd && (
        <Description
          title="Suggested Manufacture Product Name"
          content={manufactureProd}
          style={{ marginTop: "20px" }}
        />
      )}
    </div>
  );
};

export default FoodGPT;
