import { Breadcrumbs, Button, Loading, Table, Tag, Text } from "@geist-ui/core";
import { isEmpty } from "lodash";
import moment from "moment";
import { saveAs } from "file-saver";
import { useEffect, useState } from "react";
import { getRequest, postRequest } from "../../helpers/makeAPIRequests";
import { GET_DOWNLOAD_FSA_API, GET_TASKS_API } from "../../lib/endpoints";
import "./styles.css";
import Pagination from "../../components/pagination";

const tableColumns = [
  { id: 1, prop: "id", label: "Task ID" },
  { id: 2, prop: "original_file_name", label: "File Name" },
  { id: 3, prop: "task_type", label: "Task Type" },
  { id: 4, prop: "created_at", label: "Task Created At" },
  { id: 5, prop: "finished_at", label: "Task Finished At" },
  { id: 6, prop: "status", label: "Status" },
  { id: 7, prop: "action", label: "Actions" },
];

const TasksPane = () => {
  useEffect(() => {
    getTasksList();
    const interval = setInterval(() => {
      getTasksList();
    }, 60000); // 1 minute in milliseconds

    return () => clearInterval(interval);
  }, []);

  const [tasksList, setTasksList] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [showErrorText, setShowErrorText] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [count, setCount] = useState<number>(0);

  const downloadFile = async (fileName: string, taskType: string) => {
    setShowErrorText(false);
    setLoading(true);
    console.log("taskType", taskType);
    const res = await postRequest(GET_DOWNLOAD_FSA_API, {
      file_name: fileName,
      task_type: taskType,
    });
    try {
      const blob = new Blob([res.data], { type: res.headers["content-type"] });
      saveAs(blob, `${taskType}_${fileName}`);
    } catch (error) {
      setShowErrorText(true);
    }

    setLoading(false);
  };

  const getTaskTypeColor = (task: string) => {
    switch (task) {
      case "FSA":
        return "success"
        break;
      case "Attributes":
        return "warning"
        break
      case "SKU":
        return "secondary"
      default:
        return "success"
        break;
    }
  }

  const getTasksList = async (queryPage = 1) => {
    setShowErrorText(false);
    setLoading(true);
    const res = await getRequest(GET_TASKS_API, { page: queryPage });
    setCount(res?.data?.count);
    const data = res?.data?.data?.map((task: any) => {
      task.orig_task_type = task?.task_type;
      if (task?.status === "COMPLETED") {
        task.action = (
          <Button
            type="success"
            auto
            scale={0.7}
            onClick={() => downloadFile(task?.file_name, task?.orig_task_type)}
          >
            Download CSV
          </Button>
        );
      }
      task.task_type = <Tag type={getTaskTypeColor(task?.task_type)}>{task?.task_type}</Tag>;
      task.finished_at =
        task?.status === "COMPLETED"
          ? moment(new Date(task?.finished_at)).format("DD/MM/YYYY hh:mm:ss")
          : "-";
      task.status = (
        <Tag type={task?.status === "COMPLETED" ? "success" : "error"}>
          {task?.status}
        </Tag>
      );
      task.created_at = moment(new Date(task?.date_created)).format(
        "DD/MM/YYYY hh:mm:ss"
      );
      return task;
    });
    setTasksList(data);
    setLoading(false);
  };

  const handlePagination = (
    up: boolean,
    down: boolean,
    pageNumber?: number
  ) => {
    const newPage = pageNumber ? pageNumber : up ? page + 1 : page - 1;
    setPage(newPage!);
    getTasksList(newPage);
  };

  return (
    <div>
      <Breadcrumbs style={{ paddingBottom: "50px" }}>
        <Breadcrumbs.Item>Home</Breadcrumbs.Item>
        <Breadcrumbs.Item>Tasks Pane</Breadcrumbs.Item>
      </Breadcrumbs>

      <Text h6 marginTop={"20px"}>
        This page is programmed to automatically refresh every minute to ensure
        that the displayed information is up-to-date.
      </Text>

      {!isEmpty(tasksList) && (
        <div className="tasks-pane">
          <Table data={tasksList}>
            {tableColumns.map((column, index) => (
              <Table.Column key={index} prop={column?.prop} label={column?.label} />
            ))}
          </Table>
          <Pagination
            page={page}
            handlePagination={handlePagination}
            count={count}
          />
        </div>
      )}
      {isEmpty(tasksList) && !loading && (
        <Text h6 style={{ marginTop: "20px" }}>
          No Tasks created yet
        </Text>
      )}
      {loading && <Loading />}
      {showErrorText && <Text h6>Error downloading CSV.</Text>}
    </div>
  );
};

export default TasksPane;
