import "./styles.css";

const PageNotFound = () => {
  return (
    <>
      <div id="notfound">
        <div className="notfound">
          <div className="notfound-404">
            <h3>Oops! Page not found</h3>
            <h1>
              <span>4</span>
              <span>0</span>
              <span>4</span>
            </h1>
          </div>
          <h5>The page you requested was not found</h5>
        </div>
      </div>
    </>
  );
};

export default PageNotFound;
