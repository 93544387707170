import {
  AutoComplete,
  Breadcrumbs,
  Loading,
  Note,
  Table,
  Text,
} from "@geist-ui/core";
import "./styles.css";
import { useEffect, useState } from "react";
import { getRequest, postRequest } from "../../helpers/makeAPIRequests";
import {
  GET_ALL_PRODUCTS_API,
  GET_DISTRIBUTORS_API,
  GET_LANDING_PAGE_PRODUCTS_API,
  GET_OPERATORS_API,
} from "../../lib/endpoints";
import { AxiosError } from "axios";
import { isEmpty } from "lodash";
import {
  ChevronLeft,
  ChevronRight,
  ChevronsLeft,
  ChevronsRight,
} from "@geist-ui/icons";
import Pagination from "../../components/pagination";

interface dpData {
  key?: number;
  name: string;
  id: number;
  label?: string;
  value?: string;
}

interface locationData {
  "Location ID": number;
  "Location Name": string;
  value?: string;
  label?: string;
}

interface operatorData extends dpData {
  locations: locationData[];
}

interface tableColumns {
  key: number;
  prop: string;
  label: string;
  width?: number;
}

const purchasedProductsTableColumns: tableColumns[] = [
  { key: 1, prop: "id", label: "Product ID", width: 200 },
  { key: 2, prop: "name", label: "Product Name", width: 200 },
  { key: 3, prop: "L1", label: "L1 Category", width: 200 },
  { key: 4, prop: "L2", label: "L2 Category", width: 200 },
  { key: 5, prop: "L3", label: "L3 Category", width: 200 },
  { key: 6, prop: "L4", label: "L4 Category", width: 200 },
];

const recommendedProductsTableColumns: tableColumns[] = [
  { key: 1, prop: "id", label: "Product ID", width: 200 },
  { key: 2, prop: "name", label: "Product Name", width: 200 },
  { key: 3, prop: "L1", label: "L1 Category", width: 200 },
  { key: 4, prop: "L2", label: "L2 Category", width: 200 },
  { key: 5, prop: "L3", label: "L3 Category", width: 200 },
  { key: 6, prop: "L4", label: "L4 Category", width: 200 },
];

const LandingPageProductRecommendation = () => {
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [page, setPage] = useState<number>(1);

  const [dpAutoCompleteOptions, setDpAutoCompleteOptions] = useState<any>();
  const [dpData, setDpData] = useState<dpData[]>([]);
  const [selectedDp, setSelectedDp] = useState<number | null>(null);

  const [operatorAutoCompleteOptions, setOperatorAutoCompleteOptions] =
    useState<any>();
  const [operatorData, setOperatorData] = useState<operatorData[]>([]);
  const [selectedOperator, setSelectedOperator] = useState<number | null>(null);

  const [locationAutoCompleteOptions, setLocationAutoCompleteOptions] =
    useState<any>();
  const [locationData, setLocationData] = useState<locationData[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<number | null>(null);

  const [recommendedProducts, setRecommendedProducts] = useState<any[]>([]);
  const [allProducts, setAllProducts] = useState<any[]>([]);
  const [allProductsCount, setAllProductsCount] = useState<number>(0);

  useEffect(() => {
    getDpDataList();
  }, []);

  // Call External APIs
  const getDpDataList = async () => {
    setLoading(true);
    setErrorMsg(null);
    try {
      const res = await getRequest(GET_DISTRIBUTORS_API);
      const data = res?.data?.data.map((entry: any) => {
        entry.label = `${entry?.name} (${entry?.id})`;
        entry.value = entry?.name;
        return entry;
      });
      setDpData(data);
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        setErrorMsg(error.message);
      } else {
        setErrorMsg(`Unexpected Error Occured - ${error}. Please try again`);
      }
    }
    setLoading(false);
  };

  const getOperatorDataList = async (dpID: number) => {
    setLoading(true);
    setErrorMsg(null);
    try {
      const res = await getRequest(
        `${GET_OPERATORS_API}?distributor_id=${dpID}`
      );
      const data = res?.data?.data.map((entry: any) => {
        entry.label = `${entry?.name} (${entry?.id})`;
        entry.value = entry?.name;
        return entry;
      });
      setOperatorData(data);
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        setErrorMsg(error.message);
      } else {
        setErrorMsg(`Unexpected Error Occured - ${error}. Please try again`);
      }
    }
    setAllProducts([]);
    setRecommendedProducts([]);
    setLoading(false);
  };

  const getRecommendedProducts = async (
    location_id: number,
    distributor_id: number
  ) => {
    setLoading(true);
    setErrorMsg(null);
    try {
      const res = await postRequest(GET_LANDING_PAGE_PRODUCTS_API, {
        location_id,
        distributor_id,
      });
      setRecommendedProducts(res?.data?.data);
    } catch (error: any) {
      if (error instanceof AxiosError) {
        setErrorMsg(error.message);
      } else {
        setErrorMsg(error);
      }
      setRecommendedProducts([]);
    }
    setLoading(false);
  };

  const getAllProducts = async (
    locationID: number,
    pageNumber = 1,
    rowsPerPage = 10
  ) => {
    setLoading(true);
    setErrorMsg(null);
    try {
      const res = await postRequest(GET_ALL_PRODUCTS_API, {
        location_id: locationID,
        page_number: pageNumber,
        page_size: rowsPerPage,
      });
      setAllProducts(res?.data?.data);
      setAllProductsCount(res?.data?.count);
    } catch (error: any) {
      if (error instanceof AxiosError) {
        setErrorMsg(error.message);
      } else {
        setErrorMsg(error);
      }
      setAllProducts([]);
    }
    setLoading(false);
  };

  // Search Handle Functions
  const searchHandlerDpData = (currentValue: string) => {
    const relatedOptions = dpData.filter((item: any) =>
      item.value.toLowerCase().includes(currentValue.toLowerCase())
    );
    setDpAutoCompleteOptions(relatedOptions!);
  };

  const searchHandlerOperatorData = (currentValue: string) => {
    const relatedOptions = operatorData.filter((item: any) =>
      item.value.toLowerCase().includes(currentValue.toLowerCase())
    );
    setOperatorAutoCompleteOptions(relatedOptions!);
  };

  const searchHandlerLocationData = (currentValue: string) => {
    const relatedOptions = locationData.filter((item: any) =>
      item.value.toLowerCase().includes(currentValue.toLowerCase())
    );
    setLocationAutoCompleteOptions(relatedOptions!);
  };

  // Handle Slection Functions
  const handleDpSelection = (value: string) => {
    setSelectedOperator(null);
    setSelectedLocation(null);
    setAllProducts([]);
    setRecommendedProducts([]);

    const dpId = dpData?.find((dp: dpData) => dp?.value === value)?.id;
    setSelectedDp(dpId!);
    getOperatorDataList(dpId!);
  };

  const handleDpOnChange = (value: string | null | undefined) => {
    if (!value) {
      setSelectedDp(null);
      setOperatorData([]);
      setLocationData([]);
    }
  };

  const handleOperatorSelection = (value: string) => {
    setPage(1);
    setAllProducts([]);
    setRecommendedProducts([]);

    const operator = operatorData?.find(
      (op: operatorData) => op?.value === value
    );
    setSelectedOperator(operator?.id!);

    const locations = operator?.locations?.map((item: locationData) => {
      item.label = item?.["Location Name"];
      item.value = item?.["Location Name"];
      return item;
    });
    setLocationData(locations!);
    if (operator?.locations.length === 1) {
      setSelectedLocation(operator?.locations[0]?.["Location ID"]!);
      getRecommendedProducts(
        operator?.locations[0]?.["Location ID"],
        selectedDp!
      );
      getAllProducts(operator?.locations[0]?.["Location ID"]);
    }
  };

  const handleOperatorOnChange = (value: string) => {
    if (!value) {
      setSelectedOperator(null);
      setLocationData([]);
      setAllProducts([]);
      setRecommendedProducts([]);
    }
  };

  const handleLocationSelection = (value: string) => {
    setPage(1);
    const locaionId = locationData?.find(
      (loc: locationData) => loc?.value === value
    )?.["Location ID"]!;
    setSelectedLocation(locaionId);
    getRecommendedProducts(locaionId, selectedDp!);
    getAllProducts(locaionId);
  };

  const handleLocationOnChange = (value: string) => {
    if (!value) {
      setSelectedLocation(null);
      setAllProducts([]);
      setRecommendedProducts([]);
    }
  };

  const handlePagination = (
    up: boolean,
    down: boolean,
    pageNumber?: number
  ) => {
    const newPage = pageNumber? pageNumber : up ? page + 1 : page - 1;
    setPage(newPage!);
    getAllProducts(selectedLocation!, newPage);
  };

  return (
    <div>
      <Breadcrumbs style={{ paddingBottom: "50px" }}>
        <Breadcrumbs.Item>Home</Breadcrumbs.Item>
        <Breadcrumbs.Item>Product Recommendation</Breadcrumbs.Item>
        <Breadcrumbs.Item>Landing Page</Breadcrumbs.Item>
      </Breadcrumbs>

      <div className={`loading-${loading}`}>
        <div className="autocomplete-drop-down" style={{marginTop: "30px"}}>
          <AutoComplete
            options={dpAutoCompleteOptions}
            placeholder="Select a Distributor"
            onSearch={searchHandlerDpData}
            onSelect={handleDpSelection}
            onChange={handleDpOnChange}
            value={
              dpData?.find(
                (item: dpData) => parseInt(item?.value!, 10) === selectedDp
              )?.label
            }
            width="100%"
            disableFreeSolo
            clearable
            disabled={loading}
          />
        </div>
        <div className="autocomplete-drop-down">
          <AutoComplete
            options={operatorAutoCompleteOptions}
            placeholder="Select an Operator"
            onSearch={searchHandlerOperatorData}
            onSelect={handleOperatorSelection}
            onChange={handleOperatorOnChange}
            value={
              selectedDp
                ? operatorData?.find(
                    (item: operatorData) =>
                      parseInt(item?.value!, 10) === selectedOperator
                  )?.label
                : ""
            }
            width="100%"
            disableFreeSolo
            clearable
            disabled={loading || !selectedDp}
          />
        </div>
        {locationData?.length > 1 && (
          <div className="autocomplete-drop-down">
            <AutoComplete
              options={locationAutoCompleteOptions}
              placeholder="Select a Location"
              onSearch={searchHandlerLocationData}
              onSelect={handleLocationSelection}
              onChange={handleLocationOnChange}
              value={
                selectedDp
                  ? operatorData?.find(
                      (item: operatorData) =>
                        parseInt(item?.value!, 10) === selectedOperator
                    )?.label
                  : ""
              }
              width="100%"
              disableFreeSolo
              clearable
              disabled={loading || !selectedDp}
            />
          </div>
        )}
        {loading && <Loading />}
        {errorMsg && (
          <div style={{ width: "50%" }}>
            <Note type="error">{errorMsg}</Note>
          </div>
        )}
        {!isEmpty(allProducts) && !isEmpty(recommendedProducts) && (
          <div className="table-wrapper">
            <div className="data-table left">
              <Text h5 b>Purchased Products</Text>
              <Table data={allProducts}>
                <div className="table-row">
                  {purchasedProductsTableColumns?.map(
                    (column: tableColumns) => (
                      <Table.Column
                        key={column?.key}
                        prop={column?.prop}
                        label={column?.label}
                        width={column?.width}
                      />
                    )
                  )}
                </div>
              </Table>
              <Pagination handlePagination={handlePagination} page={page} count={allProductsCount}/>
            </div>
            <div className="data-table right">
            <Text h5 b>Recommended Products</Text>
              <Table data={recommendedProducts}>
                <div className="table-row">
                  {recommendedProductsTableColumns?.map(
                    (column: tableColumns) => (
                      <Table.Column
                        key={column?.key}
                        prop={column?.prop}
                        label={column?.label}
                        width={column?.width}
                      />
                    )
                  )}
                </div>
              </Table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LandingPageProductRecommendation;
