import { useEffect, useRef, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import SideMenu from "./components/sideMenu";
import { pageInterface, pages } from "./routes";
import "./App.css";
import { isEmpty } from "lodash";

function App() {
  const [openSideMenu, setOpenSideMenu] = useState<boolean>(false);
  const [showDropDown, setShowDropDown] = useState<boolean>(false);
  const [allPages, setAllPages] = useState<pageInterface[] | null>([]);

  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setOpenSideMenu(false);
        setShowDropDown(false);
      }
    }
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [menuRef]);

  useEffect(() => {
    setAllPages(createAllPagesArray(pages));
  }, []);

  const handleSideMenuToggle = () => {
    setOpenSideMenu(!openSideMenu);
  };

  const createAllPagesArray = (pagesArr: pageInterface[]) => {
    const allPages: pageInterface[] = [];
    pagesArr?.map((page: pageInterface) => {
      if (isEmpty(page?.nestedRoutes)) {
        page.longPath = page?.path;
        allPages.push(page);
      } else {
        page!.nestedRoutes!.map((subPage: pageInterface) => {
          subPage.parentId = page?.key
          subPage.longPath = `${page?.path}/${subPage?.path}`;
          allPages.push(subPage);
        });
      }
    });
    return allPages;
  };

  return (
    <div>
      <Router>
        <div ref={menuRef} style={{ zIndex: 1 }}>
          <SideMenu
            openSideMenu={openSideMenu}
            handleSideMenuToggle={handleSideMenuToggle}
            showDropDown={showDropDown}
            setShowDropDown={setShowDropDown}
          />
        </div>
        <Routes>
          {allPages?.map((page) => {
            return (
              <Route
                key={page?.key}
                path={page?.longPath}
                element={
                  <div className={`main-content open-${openSideMenu}`}>
                    <page.element openSideMenu={openSideMenu} />
                  </div>
                }
              ></Route>
            );
          })}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
