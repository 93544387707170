import { Card, Grid, Text } from "@geist-ui/core";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { pageInterface, pages } from "../../routes";
import "./styles.css";

const HomePage = () => {

  useEffect(() => {
    setAllPages(createAllPagesArray(pages));
  }, []);

  const [allPages, setAllPages] = useState<pageInterface[] | null>([]);
  const navigate = useNavigate();

  const createAllPagesArray = (pagesArr: pageInterface[]) => {
    const allPages: pageInterface[] = [];
    pagesArr?.map((page: pageInterface) => {
      if (isEmpty(page?.nestedRoutes)) {
        page.longPath = page?.path;
        page.tileTitle = page?.title;
        allPages.push(page);
      } else {
        page!.nestedRoutes!.map((subPage: pageInterface) => {
          subPage.parentId = page?.key;
          subPage.longPath = `${page?.path}/${subPage?.path}`;
          subPage.tileTitle = `${page?.title} - ${subPage?.title}`;
          allPages.push(subPage);
        });
      }
    });
    return allPages;
  };

  const handlePageOnClick = (page: pageInterface) => {
    if (page?.parentId) {
      localStorage.setItem("selectedPage", page?.parentId?.toString());
      localStorage.setItem("selectedSubPage", page?.key?.toString());
    } else {
      localStorage.setItem("selectedPage", page?.key?.toString());
    }
    navigate(page?.longPath!);
  };

  return (
    <>
      <Text h2 style={{ textAlign: "center" }}>
        AI-ML Dashboard
      </Text>
      <div className="home-page">
        <Grid.Container gap={1.5}>
          {allPages
            ?.filter(
              (page: pageInterface) =>
                page?.showOnMenu && page?.title !== "Home"
            )
            ?.map((page: pageInterface) => (
              <Grid xs={8} key={page?.key}>
                <Card
                  hoverable
                  width="100%"
                  onClick={() => handlePageOnClick(page)}
                  style={{ cursor: "pointer" }}
                >
                  <Text h4 my={0}>
                    {page?.tileTitle}
                  </Text>
                  <Card.Footer>{page?.description}</Card.Footer>
                </Card>
              </Grid>
            ))}
        </Grid.Container>
      </div>
    </>
  );
};

export default HomePage;
