import {
  Breadcrumbs,
  Button,
  Checkbox,
  Fieldset,
  Input,
  Loading,
  Note,
  Text,
  Toggle,
} from "@geist-ui/core";
import { isEmpty } from "lodash";
import { ChangeEvent, useState } from "react";
import { postRequest } from "../../helpers/makeAPIRequests";
import {
  GET_GENERATE_ATTRIBUTES_SINGLE_PRODUCT_API,
} from "../../lib/endpoints";
import "./styles.css";

interface attributesInterface {
  value: string;
  label: string;
  checked: boolean;
}

const defaultAttributes: attributesInterface[] = [
  { value: "prod_name", label: "Product Name", checked: true },
  { value: "prod_type", label: "Product Type", checked: true },
  { value: "quantity", label: "Quantity", checked: true },
  { value: "brand", label: "Brand", checked: true },
];

const GenerateAttributesSingleProduct = () => {
  const [attributes, setAttributes] =
    useState<attributesInterface[]>(defaultAttributes);
  const [showCustomAttributes, setShowCustomAttributes] =
    useState<boolean>(false);
  const [inputAttribute, setInputAttribute] = useState<string | null>(null);
  const [selectedAttributes, setSelectedAttributes] = useState<string[]>(
    defaultAttributes
      ?.filter((att: attributesInterface) => att?.checked)
      ?.map((att: attributesInterface) => att?.value)
  );
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [generatedAttributes, setGeneratedAttributes] = useState<any>(null);

  const [loading, setLoading] = useState<boolean>(false);
  const [input, setInput] = useState<string | null>(null);
  const [fsaLevels, setFsaLevels] = useState<string[]>([]);

  const handleInputChange = (e: any) => {
    setInput(e?.target?.value);
  };

  const handleClearIconClick = () => {
    setInput(null);
    setFsaLevels([]);
  };

  const handleToggleOnChange = () => {
    setErrorMsg(null);
    setShowCustomAttributes(!showCustomAttributes);
  };

  const handleInputAttributeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputAttribute(e?.target?.value);
  };

  const handleCustomAttributeAddClick = () => {
    setErrorMsg(null);
    const value = inputAttribute
      ?.split(" ")
      ?.map((word: string) => word.toLowerCase())
      ?.join("_")!;

    const alreadyAdded = attributes?.find(
      (att: attributesInterface) => att?.value === value
    );
    if (alreadyAdded) {
      setErrorMsg("The attribute is already added");
    } else {
      setAttributes([
        ...attributes,
        {
          value,
          label: inputAttribute!,
          checked: true,
        },
      ]);
      setSelectedAttributes([...selectedAttributes, value]);
    }
  };

  const handleAttributeSelection = (e: any) => {
    const unselected = attributes?.filter(
      (att: attributesInterface) => !e?.includes(att?.value)
    );
    const updatedAttributes = attributes?.map((att: attributesInterface) => {
      if (unselected?.includes(att)) {
        att.checked = false;
      }
      return att;
    });
    setSelectedAttributes(e);
    setAttributes(updatedAttributes);
  };

  const handleButtonClick = async () => {
    if (!input) {
      return null;
    }
    setGeneratedAttributes(null);
    setLoading(true);
    let res = null;
    try {
      res = await postRequest(GET_GENERATE_ATTRIBUTES_SINGLE_PRODUCT_API, {
        product_name: input,
        output_keys: selectedAttributes,
      });
    } catch (error) {
      setLoading(false);
    }
    setGeneratedAttributes(res?.data?.attributes);
    setLoading(false);
  };


  return (
    <div>
      <Breadcrumbs style={{ paddingBottom: "50px" }}>
        <Breadcrumbs.Item>Home</Breadcrumbs.Item>
        <Breadcrumbs.Item>Generate Attributes</Breadcrumbs.Item>
        <Breadcrumbs.Item>Single Product</Breadcrumbs.Item>
      </Breadcrumbs>

      <div className="fsa-single-input">
        <Text b h5 className="generate-attributes-single-text-label">
          Select attributes
        </Text>

        <Checkbox.Group
          value={selectedAttributes?.map(
            (eachAttribute: string) => eachAttribute
          )}
          onChange={handleAttributeSelection}
          width="200%"
          disabled={loading}
        >
          {attributes?.map((eachAttribute: attributesInterface) => (
            <Checkbox value={eachAttribute?.value} checked={false}>
              {eachAttribute?.label}
            </Checkbox>
          ))}
        </Checkbox.Group>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            paddingBottom: !showCustomAttributes ? "15px" : "",
          }}
        >
          <Text b h5 className="generate-attributes-single-text-label">
            Add custom attributes
          </Text>

          <Toggle
            style={{ marginTop: "5px" }}
            onChange={handleToggleOnChange}
            disabled={loading}
          />
        </div>

        {showCustomAttributes && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              paddingBottom: showCustomAttributes ? "15px" : "",
            }}
          >
            <Input
              placeholder="Add custom attributes"
              width="100%"
              onChange={handleInputAttributeChange}
              clearable
              onClearClick={() => {
                setInputAttribute(null);
                setErrorMsg(null);
              }}
            />
            <Button
              style={{ marginLeft: "10px" }}
              type="success"
              ghost
              onClick={handleCustomAttributeAddClick}
              disabled={loading || !inputAttribute}
            >
              Submit
            </Button>
          </div>
        )}

        {errorMsg && (
          <div style={{ width: "100%", marginBottom: "15px" }}>
            <Note type="error">{errorMsg}</Note>
          </div>
        )}

        <Input
          placeholder="Enter Product name"
          clearable
          width={"100%"}
          onChange={handleInputChange}
          disabled={loading}
          onClearClick={handleClearIconClick}
        >
          Product Name
        </Input>
        <div className="fsa-single-input-button" onClick={handleButtonClick}>
          <Button type="success" ghost disabled={loading || !input}>
            Get Product Attributes
          </Button>
        </div>
        {loading && <Loading />}
        {generatedAttributes && (
          <div className="fsa-single-input-fieldset">
            <Fieldset width={"200%"}>
              <Fieldset.Title>Extracted Attributes</Fieldset.Title>
              <Fieldset.Subtitle>
                {attributes
                  ?.filter((attr: attributesInterface) => attr?.checked)
                  ?.map((attr: attributesInterface) => (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "10px",
                      }}
                      key={attr?.value}
                    >
                      <Text b>{attr?.label}:&nbsp;</Text>
                      <Text margin={0}>
                        {generatedAttributes[`${attr?.value}`]}
                      </Text>
                    </div>
                  ))}
                {!isEmpty(generatedAttributes?.extras) && (
                  <div style={{marginTop: "25px"}}>
                    <Text>The below attributes were generated as extras through system</Text>
                    {Object.keys(generatedAttributes?.extras).map((key) => (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "10px",
                        }}
                        key={key}
                      >
                        <Text b>{key}:&nbsp;</Text>
                        <Text margin={0}>
                          {generatedAttributes?.extras?.[`${key}`]}
                        </Text>
                      </div>
                    ))}
                  </div>
                )}
              </Fieldset.Subtitle>
            </Fieldset>
          </div>
        )}
      </div>
    </div>
  );
};

export default GenerateAttributesSingleProduct;
