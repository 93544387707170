import {
  Breadcrumbs,
  Button,
  Card,
  Checkbox,
  Description,
  Input,
  Note,
  Text,
  Toggle,
} from "@geist-ui/core";
import { FilePlus, Upload } from "@geist-ui/icons";
import { AxiosResponse } from "axios";
import { ChangeEvent, useRef, useState } from "react";
import { postRequest } from "../../helpers/makeAPIRequests";
import {
  GET_FILE_UPLOAD_TO_S3_API,
  GET_GENERATE_ATTRIBUTES_BATCH_PROCESSING_API,
} from "../../lib/endpoints";
import "./styles.css";

interface feedbackInterface {
  title: string;
  content: string;
}

interface attributesInterface {
  value: string;
  label: string;
  checked: boolean;
}

const defaultAttributes: attributesInterface[] = [
  { value: "prod_name", label: "Product Name", checked: true },
  { value: "prod_type", label: "Product Type", checked: true },
  { value: "quantity", label: "Quantity", checked: true },
  { value: "brand", label: "Brand", checked: true },
];

const GenerateAttributesBatchProcess = () => {
  const [attributes, setAttributes] =
    useState<attributesInterface[]>(defaultAttributes);
  const [showCustomAttributes, setShowCustomAttributes] =
    useState<boolean>(false);
  const [inputAttribute, setInputAttribute] = useState<string | null>(null);
  const [selectedAttributes, setSelectedAttributes] = useState<string[]>(
    defaultAttributes
      ?.filter((att: attributesInterface) => att?.checked)
      ?.map((att: attributesInterface) => att?.value)
  );
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  const hiddenFileInput = useRef<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);
  const [fileName, setFileName] = useState<string | null>(null);
  const [showFeedback, setShowFeedback] = useState<boolean>(false);
  const [fileUploadFeedback, setFileUploadFeedback] =
    useState<feedbackInterface | null>({
      title: "File Upload",
      content: "Pending",
    });
  const [processFeedback, setProcessFeedback] =
    useState<feedbackInterface | null>({
      title: "Batch Processing",
      content: "Pending",
    });

  const handleToggleOnChange = () => {
    setErrorMsg(null);
    setShowCustomAttributes(!showCustomAttributes);
  };

  const handleInputAttributeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputAttribute(e?.target?.value);
  };

  const handleCustomAttributeAddClick = () => {
    setErrorMsg(null);
    const value = inputAttribute
      ?.split(" ")
      ?.map((word: string) => word.toLowerCase())
      ?.join("_")!;

    const alreadyAdded = attributes?.find(
      (att: attributesInterface) => att?.value === value
    );
    if (alreadyAdded) {
      setErrorMsg("The attribute is already added");
    } else {
      setAttributes([
        ...attributes,
        {
          value,
          label: inputAttribute!,
          checked: true,
        },
      ]);
      setSelectedAttributes([...selectedAttributes, value]);
    }
  };

  const handleAttributeSelection = (e: any) => {
    const unselected = attributes?.filter(
      (att: attributesInterface) => !e?.includes(att?.value)
    );
    const updatedAttributes = attributes?.map((att: attributesInterface) => {
      if (unselected?.includes(att)) {
        att.checked = false;
      }
      return att;
    });
    console.log("UNSELECTED: ", unselected);
    setSelectedAttributes(e);
    setAttributes(updatedAttributes);
  };

  const handleClick = (event: any) => {
    hiddenFileInput.current!.click();
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const fileUploaded = event?.target?.files![0];
    setFileName(fileUploaded?.name);
    setFile(fileUploaded);
  };

  console.log("Selected: ", selectedAttributes);
  console.log("Attributes: ", attributes);

  const processCSVFile = async (file: File) => {
    setShowFeedback(true);
    setFileUploadFeedback({ title: "File Upload", content: "Pending" });
    setProcessFeedback({ title: "Batch Processing", content: "Pending" });
    setLoading(true);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_type", "product_attributes");

    let fileUploadRes: AxiosResponse<any, any>;
    try {
      fileUploadRes = await postRequest(GET_FILE_UPLOAD_TO_S3_API, formData);
      console.log("File Upload Response: ", fileUploadRes?.data?.data);
      setFileUploadFeedback({
        title: "File Upload",
        content: "Succesfully uploaded to S3",
      });
    } catch (error) {
      setFileUploadFeedback({
        title: "File Upload",
        content: "Error uploading to S3",
      });
      return null;
    }
    console.log("File Upload Response: ", fileUploadRes?.data?.data)
    try {
      const data = {
        original_file_name: fileUploadRes?.data?.data?.original_filename,
        uploaded_file_name: fileUploadRes?.data?.data?.uploaded_filename,
      };
      await postRequest(GET_GENERATE_ATTRIBUTES_BATCH_PROCESSING_API, data); //run the process
      setProcessFeedback({
        title: "Batch Processing",
        content: "Started. You can view the status of the report at Tasks Pane",
      });
    } catch (error) {
      setProcessFeedback({
        title: "Batch Processing",
        content: `Error in starting batch processing: ${error}`,
      });
    }
    setLoading(false);
  };

  return (
    <div>
      <Breadcrumbs style={{ paddingBottom: "50px" }}>
        <Breadcrumbs.Item>Home</Breadcrumbs.Item>
        <Breadcrumbs.Item>Generate Attributes</Breadcrumbs.Item>
        <Breadcrumbs.Item>Batch Process</Breadcrumbs.Item>
      </Breadcrumbs>

      <Text b h5 className="generate-attributes-batch-text-label">
        Select attributes
      </Text>

      <Checkbox.Group
        value={selectedAttributes?.map(
          (eachAttribute: string) => eachAttribute
        )}
        onChange={handleAttributeSelection}
        disabled={loading}
      >
        {attributes?.map((eachAttribute: attributesInterface) => (
          <Checkbox value={eachAttribute?.value} checked={false}>
            {eachAttribute?.label}
          </Checkbox>
        ))}
      </Checkbox.Group>

      <div style={{ display: "flex", alignItems: "center" }}>
        <Text b h5 className="generate-attributes-batch-text-label">
          Add custom attributes
        </Text>

        <Toggle
          style={{ marginTop: "5px" }}
          onChange={handleToggleOnChange}
          disabled={loading}
        />
      </div>

      {showCustomAttributes && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Input
            placeholder="Add custom attributes"
            width="400px"
            onChange={handleInputAttributeChange}
            clearable
            onClearClick={() => {
              setInputAttribute(null);
              setErrorMsg(null);
            }}
          />
          <Button
            style={{ marginLeft: "10px" }}
            type="success"
            ghost
            onClick={handleCustomAttributeAddClick}
            disabled={loading || !inputAttribute}
          >
            Submit
          </Button>
        </div>
      )}

      {errorMsg && (
        <div style={{ width: "50%", marginTop: "15px" }}>
          <Note type="error">{errorMsg}</Note>
        </div>
      )}

      <Text>
        Note: Uploaded CSV file should have a column named: <b>product_name</b>.
        Files uploaded with the same name will be <b>overwritten</b>.
      </Text>

      <Text b h5 className="generate-attributes-batch-text-label">
        Upload a file to process attributes generation
      </Text>

      <div style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
        <Button
          onClick={handleClick}
          icon={<FilePlus />}
          style={{ width: "25%" }}
        >
          Select a file
        </Button>
        <input
          type="file"
          ref={hiddenFileInput}
          onChange={handleChange}
          style={{ display: "none" }}
          accept=".csv, application/vnd.ms-excel, text/csv"
        />
        {fileName && (
          <Text h6 style={{ margin: "0 0 0 20px", fontWeight: "normal" }}>
            Selected File Name: {fileName}
          </Text>
        )}
      </div>
      <Button
        icon={<Upload />}
        style={{ width: "25%", marginTop: "20px" }}
        disabled={!fileName}
        type="success"
        ghost
        onClick={() => processCSVFile(file!)}
      >
        Process CSV File
      </Button>
      {loading && (
        <Text h6 className="generate-attributes-batch-loading-text">
          The uploaded file is in process. Status of the request is shown below
        </Text>
      )}
      {showFeedback && (
        <div style={{ marginTop: "20px" }}>
          <Card width={"50%"}>
            {fileUploadFeedback && (
              <Description
                title={fileUploadFeedback?.title}
                content={fileUploadFeedback?.content}
                style={{ marginBottom: "20px" }}
              />
            )}
            {processFeedback && (
              <Description
                title={processFeedback?.title}
                content={processFeedback?.content}
                style={{ marginBottom: "20px" }}
              />
            )}
          </Card>
        </div>
      )}
    </div>
  );
};

export default GenerateAttributesBatchProcess;
