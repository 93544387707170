const PROD = process.env.REACT_APP_NAME === "ML-UI prod";

const SERVER = PROD
  ? "https://ai-ml-api.cutanddry.com"
  : "http://127.0.0.1:5000"; //TODO: change back to localhost

const API_V1 = SERVER + "/api/v1";
const API_V2 = SERVER + "/api/v2";

const COMMON = "/common";
const PRODUCT_RECOMMENDATION = "/product-recommendation";
const FSA = "/fsa";
const PRODUCT_ATTRIBUTES = "/products"
const STRING_MATCH = "/string-match"
const GORDON_AI = '/gordon-ai'
const DE_ABBREVIATION = '/abbreviations'

// Product Recommendation
export const GET_LANDING_PAGE_PRODUCTS_API =
  API_V2 + PRODUCT_RECOMMENDATION + "/landing-page";
  export const GET_ALL_PRODUCTS_API =
  API_V1 + PRODUCT_RECOMMENDATION + "/get-ordered-products";

// Product Attributes
export const GET_GENERATE_ATTRIBUTES_BATCH_PROCESSING_API = API_V1 + PRODUCT_ATTRIBUTES + "/attributes-batch-process"
export const GET_GENERATE_ATTRIBUTES_SINGLE_PRODUCT_API = API_V1 + PRODUCT_ATTRIBUTES + "/get-attributes"


// FSA
export const GET_FSA_SINGLE_PRODUCT_API = API_V1 + FSA + "/iterative-filtering";
export const GET_FSA_BATCH_PROCESSING_API = API_V1 + FSA + "/process-csv"
export const GET_DOWNLOAD_FSA_API = API_V1 + COMMON + "/download"

// common
export const GET_FILE_UPLOAD_TO_S3_API = API_V1 + COMMON + "/upload";
export const GET_TASKS_API = API_V1 + COMMON + "/get-tasks"
export const GET_DISTRIBUTORS_API = API_V1 + COMMON + "/get-distributors";
export const GET_OPERATORS_API = API_V1 + COMMON + "/get-operators";

// SKU
export const GET_SKU_MATCHING_BATCH_PROCESSING_API = API_V2 + STRING_MATCH + "/batch-process"
export const GET_SKU_MATCHING_SINGLE_PRODUCT_API = API_V2 + STRING_MATCH + "/get-filtered-products"
export const GET_SKU_MATCHING_FOOD_GPT_API = API_V1 + STRING_MATCH + "/food-gpt"

// Gordon AI
export const GET_GORDON_AI_SALES_CHAT = API_V1 + GORDON_AI + "/sales-chat"
export const GET_GORDON_GENERATE_INGREDIENTS = API_V1 + GORDON_AI + "/generate-ingredients"

// De-Abbreviation
export const GET_DE_ABBREVIATION_SINGLE_PRODUCT_API = API_V1 + DE_ABBREVIATION + "/single-product"
export const GET_DE_ABBREVIATION_BATCH_PROCESSING_API = API_V1 + DE_ABBREVIATION + "/process-csv"