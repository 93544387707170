import { Breadcrumbs, Button, Fieldset, Input, Loading } from "@geist-ui/core";
import { isEmpty } from "lodash";
import { useState } from "react";
import { postRequest } from "../../helpers/makeAPIRequests";
import { GET_DE_ABBREVIATION_SINGLE_PRODUCT_API } from "../../lib/endpoints";
import "./styles.css";

// Define interface for abbreviations
interface Abbreviations {
  [key: string]: string;
}

// De Abbreviation Page Component
const DeAbbreviationSingleProduct = () => {

    // Handle the input. output and loading states
  const [loading, setLoading] = useState<boolean>(false);
  const [input, setInput] = useState<string | null>(null);
  const [abbreviations, setAbbreviations] = useState<Abbreviations>({})
  const [fullProductName, setFullProductName] = useState<string | null>(null)

    // Handle input changes
  const handleInputChange = (e: any) => {
    setInput(e?.target?.value);
  };

    // Handle the clear button click
  const handleClearIconClick = () => {
    setInput(null)
    // setAbbreviations([])
  }

    // Handle button click for the de-abbreviation
  const handleButtonClick = async () => {

    // Check whether input is entered
    if (!input) {
        return null
    }

    // Set Loading state true while data is fetching
    setLoading(true);

    // Get the response from the backend endpoint
    const res = await postRequest(GET_DE_ABBREVIATION_SINGLE_PRODUCT_API, {
      product_name: input,
    });

    // Creating De-Abbreviation Object
    const expandedProductName = res?.data?.["full_product_name"]
    const abbreviationMappings = res?.data?.["abbreviations"]

    // Set the results in state
    setFullProductName(expandedProductName);
    setAbbreviations(abbreviationMappings);
    setLoading(false);
  };

  return (
    <div>
      <Breadcrumbs style={{ paddingBottom: "50px" }}>
        <Breadcrumbs.Item>Home</Breadcrumbs.Item>
        <Breadcrumbs.Item>De-Abbreviation</Breadcrumbs.Item>
        <Breadcrumbs.Item>Single Product</Breadcrumbs.Item>
      </Breadcrumbs>

      <div className="abbreviation-single-input">
        <Input
          placeholder="Enter Product name"
          clearable
          width={"100%"}
          onChange={handleInputChange}
          disabled={loading}
          onClearClick={handleClearIconClick}
        >
          Product Name
        </Input>
        <div className="abbreviation-single-input-button" onClick={handleButtonClick}>
          <Button type="success" ghost disabled={loading || !input}>
            Get De-Abbreviation
          </Button>
        </div>
        {loading && <Loading />}
        {!isEmpty(fullProductName) && <div className="abbreviation-single-input-fieldset">
          <Fieldset width={"200%"}>
            <Fieldset.Title>Expanded Product Name</Fieldset.Title>
            <Fieldset.Subtitle>
              {/* Display Full Product Name */}
              <div>
                {fullProductName}
              </div>

              <h4 className="abbreviation-mappings">
                Abbreviations Mappings to De-Abbreviations
              </h4>

              {/* Displaying Mappings of abbreviations to the de-abbreviations */}
              {Object.entries(abbreviations).map(([word, mapping]) => (
                  <div key={word}>
                    {word} : {mapping}
                  </div>
                ))}
            </Fieldset.Subtitle>
          </Fieldset>
        </div>}
      </div>
    </div>
  );
};


export default DeAbbreviationSingleProduct;
