import {
  Breadcrumbs,
  Button,
  Description,
  Fieldset,
  Input,
  Loading,
} from "@geist-ui/core";
import React, { useRef, useState } from "react";
import { TypeAnimation } from "react-type-animation";
import { postRequest } from "../../helpers/makeAPIRequests";
import { GET_GORDON_AI_SALES_CHAT } from "../../lib/endpoints";
import Typewriter from "typewriter-effect";

const GordanAISalesChat = () => {
  const [question, setQuestion] = useState<null | string>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [response, setResponse] = useState<null | string>(null);
  const [viewResponse, setViewResponse] = useState<any>([]);

  const handleQuestion = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuestion(e?.target?.value);
  };

  const handleAPIRequest = async () => {
    setLoading(true);
    setViewResponse(["Creating the agent..."]);
    setResponse("Creating the agent...");
    let res: any
    try {
      res = await postRequest(GET_GORDON_AI_SALES_CHAT, {
        chat_type: "SALES_DATA",
        distributor: "SOUTHWEST-TRADERS",
        question,
        user_id: "ai-ml-dashboard@cutanddry.com"
      });
      setResponse(res?.data?.data?.output);
      setLoading(false);
      setViewResponse([res?.data?.data?.output]);
    } catch (error) {
      setLoading(false);
      setResponse("An error occured, Please try again later.");
    }
    // const res = await postRequest(GET_GORDON_AI_SALES_CHAT, {
    //   chat_type: "SALES_DATA",
    //   distributor: "SOUTHWEST-TRADERS",
    //   question,
    // });
    // setResponse(res?.data?.data?.output);
    // setLoading(false);
    // setViewResponse([res?.data?.data?.output]);
    // createViewResponse(res?.data?.data?.output);
  };

  const createViewResponse = (inputSentence: string) => {
    const wordsArray = inputSentence.split(" ");
    const newArray = [];

    for (let i = 0; i < wordsArray.length; i++) {
      const wordsWithPreceding = wordsArray.slice(0, i + 1).join(" ");
      newArray.push(50);
      newArray.push(wordsWithPreceding);
      if (i == wordsArray.length - 1) {
        newArray.push(50);
      }
    }

    viewResponse.current = newArray;
  };

  return (
    <div>
      <Breadcrumbs style={{ marginBottom: "20px" }}>
        <Breadcrumbs.Item>Home</Breadcrumbs.Item>
        <Breadcrumbs.Item>Gordon AI</Breadcrumbs.Item>
        <Breadcrumbs.Item>Chat with your sales data</Breadcrumbs.Item>
      </Breadcrumbs>
      <Fieldset style={{ marginTop: "25px", marginBottom: "20px" }}>
        <Fieldset.Title>
          Querying your sales data has never been easier.
        </Fieldset.Title>
        <Fieldset.Subtitle style={{ width: "97%" }}>
          Type in your question regarding the sales that resulted from Southwest
          Traders for the past 6 months (Dating back from September, 2023). You
          can ask questions based on the operator, the sold products, total
          sales amount and transaction date. <br />
          <br />
          Sample Open-Ended Questions: <br />
          (1). Which operator had the highest number of orders and which
          operator generated the highest sales amount? <br />
          (2). What is the most sold product during this period? <br />
          (3). What is the average sales amount for the month of June?
          <br />
          <br />
          As of now, the system is capable of generating answers for the
          following tasks as described in{" "}
          <a href="https://docs.google.com/document/d/1kLDQ-BG0g3vWXTY5USnF_MDajkmzyrol2_jKMbmFfgI/edit">
            Yes, Chef Queries
          </a>
          <br />
          (1). Lost Business - Show me top 5 operators who recently stopped
          ordering. <br />
          (2). At Risk Business - Show me top 5 operators that might churn soon. <br />
          (4). Most Profitable Customers - Show me the 5 most profitable customers. <br />
          (6). Slowed down products - Show me the top 5 of underperforming SKUs <br />
          (7). Most Profitable SKUs - Show me the top 5 most profitable SKUs <br />
          (9). Least Profitable SKUs - Show me a list of SKUs we should consider removing
          <br />
          <br />
          Try to be as descriptive as possible when asking the question related
          to the data. <br />
          Sample Question: A restaurant that has stopped ordering is one that
          has made an order before but hasn't made an order in the last 60 days.
          Show me top 5 restaurants that have stopped ordering, prioritized by
          the total order. Also list the last date that the restaurant made a
          purchase.
          <br />
          <br />
          The agent creation takes ~ 10 seconds, and we're looking to reduce the
          time taken.
        </Fieldset.Subtitle>
      </Fieldset>

      <Input
        placeholder="Type in your question"
        width="100%"
        onChange={handleQuestion}
      />
      <Button
        type="success"
        ghost
        auto
        scale={0.7}
        disabled={!question}
        style={{ marginTop: "10px" }}
        onClick={handleAPIRequest}
      >
        Ask Question
      </Button>

      {loading && <Loading />}

      <div>
        <Description
          title="Generated Output"
          style={{ marginTop: "20px" }}
          content={
            response
            // <TypeAnimation
            //   preRenderFirstString={true}
            //   sequence={viewResponse.current}
            //   // sequence={[50, 'The', 50, 'The operator', 50, 'The operator with', 50, 'The operator with the', 50, 'The operator with the highest', 50, 'The operator with the highest number', 50, 'The operator with the highest number of', 50, 'The operator with the highest number of orders', 50, 'The operator with the highest number of orders and', 50, 'The operator with the highest number of orders and the', 50, 'The operator with the highest number of orders and the highest', 50, 'The operator with the highest number of orders and the highest sales', 50, 'The operator with the highest number of orders and the highest sales amount', 50, 'The operator with the highest number of orders and the highest sales amount is', 50, 'The operator with the highest number of orders and the highest sales amount is Yogurt', 50, 'The operator with the highest number of orders and the highest sales amount is Yogurt Mill.', 50]}
            //   speed={75}
            //   style={{ fontSize: "14px" }}
            //   cursor={false}
            //   // repeat={Infinity}
            // />
            // <Typewriter
            //   options={{
            //     delay: 20,
            //     autoStart: true,
            //     strings: viewResponse, // ["Hello World"],
            //     deleteSpeed: 0,
            //     loop: true
            //   }}

            // onInit={(typewriter) => {
            //   typewriter
            //     .typeString(response!)
            //     .callFunction(() => {
            //       console.log("String typed out!");
            //     })
            //     // .pauseFor(2500)
            //     // .deleteAll()
            //     // .callFunction(() => {
            //     //   console.log("All strings were deleted");
            //     // })
            //     .start();
            // }}
            // />
          }
        />
      </div>
    </div>
  );
};

export default GordanAISalesChat;
