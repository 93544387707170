import {
  Badge,
  Breadcrumbs,
  Button,
  Code,
  Grid,
  Input,
  Loading,
  Note,
  Table,
  Text,
} from "@geist-ui/core";
import { isEmpty } from "lodash";
import { useState } from "react";
import { postRequest } from "../../helpers/makeAPIRequests";
import { GET_GORDON_GENERATE_INGREDIENTS } from "../../lib/endpoints";

interface inputFieldsInterface {
  key: number;
  label: string;
  placeholder?: string;
  handleFunc: (field: string, value: string) => void;
  html?: any;
}

interface ingredientsInterface {
  ingredient_name: string;
  used_by_restaurant: boolean;
  nested_ingredients?: ingredientsInterface[];
}

interface tableDataInterface {
  primaryIngredients: string;
  primaryIngredientsUsage: string;
  secondaryIngredients: string | null;
  secondaryIngredientsUsage: string | null;
}

const data = [
  {
    property: "type",
    description: "Content type",
    type: "secondary | warning",
    default: "-",
  },
  {
    property: "Component",
    description: "DOM element to use",
    type: <Code>string</Code>,
    default: "-",
  },
  {
    property: <Text b>bold</Text>,
    description: "Bold style",
    type: <Code>boolean</Code>,
    default: <Code>true</Code>,
  },
];

const GordanAIIngredientsGeneration = () => {
  const [name, setName] = useState<string | null>(null);
  const [cuisine, setCuisine] = useState<string | null>(null);
  const [description, setDescription] = useState<string | null>(null);
  const [category, setCategory] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [ingredients, setIngredients] = useState<
    ingredientsInterface[] | null[]
  >([]);
  const [tableData, setTableData] = useState<tableDataInterface[] | []>([]);

  const handleInputFields = (field: string, value: string | null) => {
    switch (field) {
      case "name":
        setName(value);
        break;
      case "cuisine":
        setCuisine(value);
        break;
      case "description":
        setDescription(value);
        break;
      case "category":
        setCategory(value);
        break;
      default:
        break;
    }
  };

  const inputFields: inputFieldsInterface[] = [
    {
      key: 1,
      html: (
        <Badge.Anchor>
          <Badge scale={0} type="error" dot />
          Menu Item
        </Badge.Anchor>
      ),
      label: "name",
      handleFunc: handleInputFields,
    },
    {
      key: 2,
      html: (
        <Badge.Anchor>
          <Badge scale={0} type="error" dot />
          Cuisine
        </Badge.Anchor>
      ),
      label: "cuisine",
      placeholder: "e.g., American (Traditional)",
      handleFunc: handleInputFields,
    },
    {
      key: 3,
      html: (
        <Badge.Anchor>
          <Badge scale={0} type="error" dot />
          Description
        </Badge.Anchor>
      ),
      label: "description",
      handleFunc: handleInputFields,
    },
    {
      key: 4,
      html: (
        <Badge.Anchor>
          <Badge scale={0} type="error" dot />
          Category
        </Badge.Anchor>
      ),
      label: "category",
      placeholder: "e.g., Burgers",
      handleFunc: handleInputFields,
    },
  ];

  const generateIngredients = async () => {
    setTableData([])
    setLoading(true);
    setErrorMsg(null);
    console.log(name, cuisine, category, description);
    if (!name || !cuisine || !category || !description) {
      setErrorMsg("All fields are mandatory.");
    } else {
      const body = {
        name,
        cuisine,
        description,
        category,
      };
      try {
        const results = await postRequest(
          GET_GORDON_GENERATE_INGREDIENTS,
          body
        );
        setIngredients(results?.data?.data);
        generateTableData(results?.data?.data);
      } catch (error) {
        setLoading(false);
      }
    }
    setLoading(false);
  };

  const generateTableData = (data: ingredientsInterface[]) => {
    const table = data?.map((item) => {
      const tableRow: tableDataInterface = {
        primaryIngredients: item?.ingredient_name,
        primaryIngredientsUsage: item?.used_by_restaurant ? "True" : "False",
        secondaryIngredients: item?.nested_ingredients
          ? item?.nested_ingredients
              ?.map((nestedIngredient) => nestedIngredient?.ingredient_name)
              .join(", ")
          : "-",
        secondaryIngredientsUsage: item?.nested_ingredients
          ? item?.nested_ingredients
              ?.map((nestedIngredient) =>
                nestedIngredient?.used_by_restaurant ? "True" : "False"
              )
              .join(", ")
          : "-",
      };
      return tableRow
    });
    setTableData(table)
  };

  return (
    <div>
      <Breadcrumbs style={{ marginBottom: "20px" }}>
        <Breadcrumbs.Item>Home</Breadcrumbs.Item>
        <Breadcrumbs.Item>Gordon AI</Breadcrumbs.Item>
        <Breadcrumbs.Item>Generate Ingredients</Breadcrumbs.Item>
      </Breadcrumbs>

      <Grid.Container gap={2} style={{ marginTop: "15px" }}>
        {inputFields?.map((inputField: inputFieldsInterface) => {
          return (
            <Grid xs={24} md={12} key={inputField.key}>
              <Input
                clearable
                placeholder={inputField.placeholder}
                width="100%"
                onChange={(e) =>
                  inputField?.handleFunc(inputField?.label, e?.target?.value)
                }
                onClearClick={() =>
                  inputField?.handleFunc(inputField?.label, null!)
                }
              >
                {inputField?.html ? inputField?.html : inputField?.label}
              </Input>
            </Grid>
          );
        })}
      </Grid.Container>

      {errorMsg && (
        <div style={{ width: "50%", marginTop: "15px" }}>
          <Note type="error">{errorMsg}</Note>
        </div>
      )}

      <Button
        type="success"
        ghost
        auto
        scale={0.7}
        style={{ marginTop: "15px" }}
        onClick={generateIngredients}
      >
        Generate Ingredients
      </Button>

      {loading && <Loading />}

      {!isEmpty(tableData) && (
        <Table data={tableData!} style={{ marginTop: "30px" }} width="100%">
          <Table.Column prop="primaryIngredients" label="Primary Ingredient" />
          <Table.Column
            prop="primaryIngredientsUsage"
            label="Primary Ingredient Usage"
          />
          <Table.Column prop="secondaryIngredients" label="Secondary Ingredients" />
          <Table.Column prop="secondaryIngredientsUsage" label="Secondary Ingredient Usage" />
        </Table>
      )}
    </div>
  );
};

export default GordanAIIngredientsGeneration;
