import { Text } from "@geist-ui/core";
import {
  ChevronsLeft,
  ChevronLeft,
  ChevronRight,
  ChevronsRight,
} from "@geist-ui/icons";

interface PaginationProps {
    page: number;
    handlePagination: (next: boolean, prev: boolean, page?: number) => void;
    count: number;
}

const Pagination = ({ page, handlePagination, count }: PaginationProps) => {
  return (
    <div className="table-pagination">
      <Text small className="pagination-text">
        Displaying rows : {(page - 1) * 10 + 1} - {Math.min(page * 10, count)} of {count}
      </Text>
      <div
        className={`pagination-button-display-${page !== 1}`}
        onClick={() => page !== 1 ? handlePagination(false, true, 1) : null}
      >
        <ChevronsLeft />
      </div>
      <div
        className={`pagination-button-display-${page !== 1} pagination-buttons`}
        onClick={() => page !== 1 ? handlePagination(false, true) : null}
      >
        <ChevronLeft />
      </div>
      <div
        className={`pagination-button-display-${
          page !== Math.ceil(count / 10)
        }`}
        onClick={() => page !== Math.ceil(count / 10) ? handlePagination(true, false) : null}
      >
        <ChevronRight />
      </div>
      <div
        className={`pagination-button-display-${
          page !== Math.ceil(count / 10)
        }`}
        onClick={() => page !== Math.ceil(count / 10) ? handlePagination(false, true, Math.ceil(count / 10)) : null}
      >
        <ChevronsRight />
      </div>
    </div>
  );
};

export default Pagination;
