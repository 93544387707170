import { Menu, ChevronDown, ChevronUp } from "@geist-ui/icons";
import Card from "@geist-ui/core/esm/card/card";
import "./styles.css";
import { pageInterface, pages } from "../../routes";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";
import React from "react";

interface inputProps {
  openSideMenu: boolean;
  handleSideMenuToggle: any;
  showDropDown: boolean;
  setShowDropDown: React.Dispatch<React.SetStateAction<boolean>>;
}

if (!localStorage.getItem("selectedPage")) {
  localStorage.setItem("selectedPage", "1");
}
if (!localStorage.getItem("displayDropDown")) {
  localStorage.setItem("displayDropDown", "");
}

const SideMenu = ({
  openSideMenu,
  handleSideMenuToggle,
  showDropDown,
  setShowDropDown,
}: inputProps) => {
  const navigate = useNavigate();

  const handleMenuOptionOnClick = (page: pageInterface, tempPageKey?:number) => {
    if (!isEmpty(page?.nestedRoutes)) {

      // Display Sub menu drop down and handle drop down icon
      if (localStorage.getItem("displayDropDown") === page?.key?.toString() && openSideMenu) {
        localStorage.setItem("displayDropDown", "");
      } else {
        localStorage.setItem("displayDropDown", page?.key?.toString())
      }
      setShowDropDown(!showDropDown);
      if (!openSideMenu) {
        handleSideMenuToggle();
      }
    } else {
      localStorage.setItem("displayDropDown", "");
      localStorage.setItem("selectedPage", page?.key.toString());
      if (openSideMenu) {
        handleSideMenuToggle();
      }
      localStorage.setItem("selectedSubPage", "");
      setShowDropDown(false);
      navigate(page.path);
    }
  };

  const handleSubMenuOptionOnClick = (
    subPage: pageInterface,
    page: pageInterface
  ) => {
    localStorage.setItem("selectedSubPage", subPage?.key.toString());
    localStorage.setItem("selectedPage", page?.key.toString());
    localStorage.setItem("displayDropDown", "");
    if (openSideMenu) {
      handleSideMenuToggle();
    }
    setShowDropDown(false);
    navigate(`${page?.path}/${subPage.path}`);
  };
  return (
    <div className={`side-bar side-bar-open-${openSideMenu}`}>
      {
        <Card style={{ height: "100vh" }}>
          <div
            className="menu-item menu-heading"
            onClick={handleSideMenuToggle}
          >
            <Menu className="menu-data" />
            {openSideMenu && (
              <h4 className="menu-data menu-name">AI ML Dashboard</h4>
            )}
          </div>
          {pages
            .filter((page) => page.showOnMenu)
            .map((page, index) => {
              return (
                <React.Fragment key={index}>
                  <div
                    className={`menu-item menu-item-background-${
                      localStorage.getItem("selectedPage") ===
                        page.key.toString() 
                       &&
                        localStorage.getItem("displayDropDown") !== page?.key?.toString()
                    }`}
                    onClick={() => handleMenuOptionOnClick(page, page?.parentId)}
                  >
                    {<page.icon className="menu-data" />}
                    {openSideMenu && (
                      <h5 className="menu-data menu-name">{page.title}</h5>
                    )}
                    {openSideMenu &&
                      localStorage.getItem("displayDropDown") !== page?.key?.toString() &&
                      !isEmpty(page?.nestedRoutes) &&
                      (
                        <div style={{ marginLeft: "auto", display: "flex" }}>
                          <ChevronDown className="drop-down" size={32} />
                        </div>
                      )}
                    {openSideMenu &&
                      localStorage.getItem("displayDropDown") === page?.key?.toString() &&
                      !isEmpty(page?.nestedRoutes) &&
                      (
                        <div style={{ marginLeft: "auto", display: "flex" }}>
                          <ChevronUp className="drop-down" size={32} />
                        </div>
                      )}
                  </div>
                  {openSideMenu &&
                    localStorage.getItem("displayDropDown") === page?.key?.toString() &&
                    page?.nestedRoutes
                      ?.filter((subPage) => subPage?.showOnMenu)
                      ?.map((subPage, subIndex) => {
                        return (
                          <div
                            className={`menu-item sub-menu menu-item-background-${
                              localStorage.getItem("selectedSubPage") ===
                              subPage?.key.toString()
                            }`}
                            key={`${index}-${subIndex}`}
                            onClick={() =>
                              handleSubMenuOptionOnClick(subPage, page)
                            }
                          >
                            {<page.icon className="menu-data" />}
                            {openSideMenu && (
                              <h5 className="menu-data menu-name">
                                {subPage.title}
                              </h5>
                            )}
                          </div>
                        );
                      })}
                </React.Fragment>
              );
            })}
        </Card>
      }
    </div>
  );
};

export default SideMenu;
